<template>
  <div class="chart">
    <canvas :height="250" :id="ordersChartID"></canvas>
  </div>
</template>
<script>
import Chart from "chart.js/auto";
import { ordersChart } from "@/components/Charts/Chart";
export default {
  name: "graph",
  data: function () {
    return {
      salesChartID: "salesChart",
      ordersChartID: "ordersChart",
    };
  },
  mounted() {
    ordersChart.createChart(this.ordersChartID);
  },
};
</script>
