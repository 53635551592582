<template>
  <div>
    <v-container fluid class="px-3">
      <v-row>
        <v-col md="12" class="z-index-2">
          <v-card class="bg-transparent mb-6">
            <v-row>
              <v-col lg="6">
                <h2 class="text-h2 text-black font-weight-bolder mb-0">
                  Dashboard
                </h2>
              </v-col>
            </v-row>
          </v-card>
          <!-- <v-card class="card-shadow pa-4 border-radius-l">
           
            <GmapMap
            :zoomControl="false" 
            :mapTypeControl="false"
            :scaleControl="false"
            :streetViewControl="false" 
            :rotateControl="false" 
            fullscreenControl="false"
            :disableDefaultUI="false"
              :center="center"
              :zoom="zoom"
              ref="map"
              map-type-id="terrain"
              style="width: 500px; height: 300px"
              class="border-radius-l"
            >
              <GmapCircle
                v-for="(pin, index) in markers"
                :key="index"
                :center="pin.position"
                :radius="10000"
                :visible="true"
                :options="{
                  fillColor: '#00B57D',
                  fillOpacity: 0.5,
                  strokeColor: '#00B57D',
                }"
              ></GmapCircle>
            </GmapMap>
          </v-card> -->
          <v-row>
            <v-col cols="12" md="6" xl="8">
              <v-card class="mb-6 card-shadow border-radius-l pa-4">
                <v-row class="ma-2 justify-space-between">
                  <div class="mt-1">
                    <span class="font-weight-bold" style="font-size: 18px"
                      >1.150</span
                    >
                    <div style="width: 200px">
                    <v-select
                      :items="quests"
                      value="Alle Quests"
                      color="rgba(0,0,0,.6)"
                      class="px-0 font-size-input text-light-input placeholder-light border-radius-md select-style mt-2 mb-0"
                      flat solo hide-details
                      width="100"
                      
                    >
                    </v-select></div>
                  </div>
                  <div style="width: 180px">
                    <v-select
                      :items="modes"
                      value="Letzte Woche"
                      color="rgba(0,0,0,.6)"
                      class="input-style font-size-input text-light-input placeholder-light border-radius-md select-style mt-2 mb-0"
                      outlined
                      width="100"
                      height="35"
                    >
                    </v-select>
                  </div>
                </v-row>
                <graph />
              </v-card>
            </v-col>
            <v-col cols="12" md="6" xl="4">
              <v-card class="mb-6 card-shadow border-radius-l pa-4">
                <v-row class="ma-2 justify-space-between">
                  <div class="mt-1">
                    <span class="font-weight-bold" style="font-size: 18px"
                      >12.345</span
                    >
                    <div style="width: 180px">
                    <v-select
                      :items="visitors"
                      value="Alle Besucher"
                      color="rgba(0,0,0,.6)"
                      class="font-size-input text-light-input placeholder-light border-radius-md select-style mt-2 mb-0"
                      flat solo hide-details
                      width="100"
                      height="35"
                    >
                    </v-select>
                  </div>
                  </div>
                  <div style="width: 180px">
                    <v-select
                      :items="modes"
                      value="Letzte Woche"
                      color="rgba(0,0,0,.6)"
                      class="input-style font-size-input text-light-input placeholder-light border-radius-md select-style mt-2 mb-0"
                      outlined
                      width="100"
                      height="35"
                    >
                    </v-select>
                  </div>
                </v-row>
                <bar />
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="8">
              <v-card class="mb-6 card-shadow border-radius-l pa-4">
                <v-row no-gutters> </v-row>
              </v-card>
            </v-col>
            <v-col cols="12" md="4">
              <v-card class="mb-6 card-shadow border-radius-l pa-4">
                <v-row no-gutters> </v-row>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <!-- <v-row class="mt-6">
      
        <v-col lg="6" class="pt-0 z-index-2">
          <v-card class="card-shadow border-radius-l">
            <div class="card-header-padding pb-0">
              <div class="font-weight-bold text-typo text-h6 text-typo mb-2">
                Meistgesuchte Begriffe
              </div>
             
            </div>
            <v-card-text class="card-padding">
              <bar-chart-horizontal></bar-chart-horizontal>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col lg="6" class="pt-0 z-index-2">
          <v-card class="card-shadow border-radius-l">
            <div class="card-header-padding pb-0">
              <div class="font-weight-bold text-typo text-h6 text-typo mb-2">
                Nutzer nach Plattform
              </div>
              
            </div>
            <v-card-text class="card-padding">
              <pie-chart></pie-chart>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row> -->
    </v-container>
  </div>
</template>

<script>
import { ordersChart } from "@/components/Charts/Chart";
import Chart from "chart.js/auto";
import Graph from "./Widgets/Graph.vue";
import Bar from "./Widgets/Bar.vue";
import SalesCountry from "./Widgets/SalesCountry.vue";
import WidgetGlobe from "./Widgets/Globe.vue";
import PieChart from "./Widgets/PieChart.vue";
import BarChartHorizontal from "./Widgets/BarChartHorizontal.vue";
export default {
  name: "Dashboard",
  components: {
    SalesCountry,
    WidgetGlobe,
    PieChart,
    BarChartHorizontal,
    Graph,
    Bar,
  },
  data: function () {
    return {
      modes: ["Letzte Woche", "Letzter Monat", "Letztes Jahr"],
      quests: ["Alle Quests", "Events", "Rätsel"],
      visitors: ["Alle Besucher"],
      salesChartID: "salesChart",
      ordersChartID: "ordersChart",
      zoom: 5,
      center: { lat: 59.339025, lng: 18.065818 },
      markers: [
        {
          Id: 1,
          name: "Oslo",
          position: { lat: 59.923043, lng: 10.752839 },
          radius: 10000,
        },
        {
          Id: 2,
          name: "Stockholm",
          position: { lat: 59.339025, lng: 18.065818 },
          radius: 10000,
        },
        {
          Id: 3,
          name: "Copenhagen",
          position: { lat: 55.675507, lng: 12.574227 },
          radius: 10000,
        },
        {
          Id: 4,
          name: "Berlin",
          position: { lat: 52.521248, lng: 13.399038 },
          radius: 10000,
        },
        {
          Id: 5,
          name: "Paris",
          position: { lat: 48.856127, lng: 2.346525 },
          radius: 10000,
        },
      ],
    };
  },

  computed: {
    glossary() {
      if (this.$store.getters.glossary.data) {
        return this.$store.getters.glossary.data;
      } else {
        return [];
      }
    },
    ratings() {
      if (this.$store.getters.ratings.data) {
        return this.$store.getters.ratings.data;
      } else {
        return [];
      }
    },
    categories() {
      return this.$store.getters.categories.data;
    },
    users() {
      return this.$store.getters.users.data;
    },
    views() {
      return this.$store.getters.views.data;
    },
  },
  mounted() {
    this.$store.dispatch("userInformations");
    this.$store.dispatch("getGlossary");
    this.$store.dispatch("getUsers");
    this.$store.dispatch("getCategory");
    this.$store.dispatch("getViews");
    this.$store.dispatch("getRatings");
    // new Chart(document.getElementById(this.salesChartID).getContext("2d"), {
    //   type: "bar",
    //   data: {
    //     labels: ["Jan", "Feb", "Mär", "Apr", "Mai", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Dez"],
    //     datasets: [
    //       {
    //         label: "Nutzer",
    //         tension: 0.4,
    //         borderWidth: 0,
    //         borderRadius: 4,
    //         borderSkipped: false,
    //         backgroundColor: "#fff",
    //         data: [0, 3, 5, 8, 12, 22, 38, 100, 123, 120, 160,180],
    //         maxBarThickness: 6,
    //       },
    //     ],
    //   },
    //   options: {
    //     responsive: true,
    //     maintainAspectRatio: false,
    //     plugins: {
    //       legend: {
    //         display: false,
    //       },
    //     },
    //     interaction: {
    //       intersect: false,
    //       mode: "index",
    //     },
    //     scales: {
    //       y: {
    //         grid: {
    //           drawBorder: false,
    //           display: false,
    //           drawOnChartArea: false,
    //           drawTicks: false,
    //         },
    //         ticks: {
    //           suggestedMin: 0,
    //           suggestedMax: 500,
    //           beginAtZero: true,
    //           padding: 15,
    //           font: {
    //             size: 14,
    //             family: "Open Sans",
    //             style: "normal",
    //             lineHeight: 2,
    //           },
    //           color: "#fff",
    //         },
    //       },
    //       x: {
    //         grid: {
    //           drawBorder: false,
    //           display: false,
    //           drawOnChartArea: false,
    //           drawTicks: false,
    //         },
    //         ticks: {
    //           display: false,
    //         },
    //       },
    //     },
    //   },
    // });
    // ordersChart.createChart(this.ordersChartID);
  },
};
</script>
